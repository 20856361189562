<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <!-- Шапка документа -->
      <b-row class="align-end">
        <b-col class="company-details__rowData dflex valign-end">
          <div>Директору {{ companyData.ShortName }}</div>
          <div>{{ companyData.ChiefFullNameDative }}</div>
          <span> от </span>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="userFormData.ResponsiblePersonPosition"
              class="company-details__input"
              placeholder="Должность"
            />
          </div>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="userFormData.ResponsiblePersonFullName"
              class="company-details__input"
              placeholder="ФИО ответственного лица"
            />
          </div>
          <span>
            {{ customerData.Name }}
          </span>
        </b-col>
      </b-row>
      <br />
      <br />
      <br />

      <!-- Тело заявления -->
      <b-row>
        <b-col class="statement-type">
          <p>{{ statementTitle }}</p>
        </b-col>
      </b-row>
      <div class="dflex valign-center">
        <span class="statement-text">Прошу расторгнуть договор №</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.ContractNumber"
            class="company-details__input"
            placeholder="Номер договора"
          />
        </div>
        <span class="statement-text">от</span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="userFormData.ContractDate"
            class="company-details__date"
          />
        </div>
        <span class="statement-text">с</span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="userFormData.StartDate"
            class="company-details__date"
          />
        </div>
        .
        <span class="statement-text">
          Имеющуюся задолженность согласно акта сверки по оплате Услуг перед Оператором связи
          обязуюсь возместить в течение 3 (трех) рабочих дней с даты расторжения договора.
        </span>
      </div>
      <br />

      <!-- Подпись -->
      <b-row>
        <b-col class="dflex align-end valign-center">
          <BaseTextInput
            v-model="userFormData.ChiefPosition"
            class="company-details__input"
            placeholder="Должность руководителя"
          />
          <span> ____________ / </span>
          <BaseTextInput
            v-model="userFormData.ChiefFullName"
            class="company-details__input"
            placeholder="ФИО руководителя"
          />
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapState } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      statementTitle: 'Заявление',
      userFormData: {}
    };
  },

  components: {
    BaseTextInput,
    BaseInputDate,
    ServicesDescription
  },

  mounted() {
    this.userFormData = { ...this.currentData };
  },

  computed: {
    ...mapState({
      companyData: (state) => state.statements.module.companyData,
      customerData: (state) => state.statements.module.customerData
    }),

    currentData() {
      return {
        ContractNumber: this.customerData.ContractNumber,
        ContractDate: this.customerData.ContractDate.replace(/T00:00:00/, ''),
        ChiefPosition: this.customerData.ChiefPosition,
        ChiefFullName: this.customerData.ChiefFullName,
        ResponsiblePersonPosition: '',
        ResponsiblePersonFullName: '',
        StartDate: ShowCurrentDate()
      };
    }
  },

  methods: {
    // Формирует объект для отправки на сервер
    createModelToSend() {
      return {
        CurrentCustomer: {
          ContractNumber: this.userFormData.ContractNumber,
          ContractDate: `${this.userFormData.ContractDate}T00:00:00`,
          ChiefPosition: this.userFormData.ChiefPosition,
          ChiefFullName: this.userFormData.ChiefFullName
        },
        AdditionalInformation: {
          StartDate: `${this.userFormData.StartDate}T00:00:00`
        },
        ResponsiblePersonPosition: this.userFormData.ResponsiblePersonPosition,
        ResponsiblePersonFullName: this.userFormData.ResponsiblePersonFullName
      };
    },

    getDocument() {
      this.createDocument(this.createModelToSend());
    }
  }
};
</script>

<style></style>
